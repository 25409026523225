<template>
    <b-container class="text-center">

        <br><br>
        <div v-if="true">
            Il sito è in manutenzione
        </div>
        
        <div v-else>
            Accesso in corso...
            <br><br>
            <Spinner v-if="loading" />
            <!--
            <div v-else>
                <p>jpToken: {{jpToken}}</p>
            </div>
            -->
        </div>
        <br><br>
    </b-container>
</template>

<script>
//import Router from 'vue-router'
import Spinner from '../atoms/spinner'
//import Api from '../../api'

export default {
    name: 'maintenance',
    metaInfo: {
      title: 'in manutenzione',
    },
    components:
    {
        Spinner,
    },
    props:
    {
    },
    data()
    {
        return {
            //data: '',
            //loading: true,
        }
    },
    mounted()
    {
        /*
        let _this = this

        _this.data = _this.$router.currentRoute.query.data
        _this.jpSso()
        */
    },
    methods:
    {
        /*
        jpSso()
        {
            let _this = this 

            Api.jpSso({
                data: _this.data,
            })
            .then((results) => {

              window.location.href = '/?token='+results.token

            })

        }
        */
    },
}
</script>